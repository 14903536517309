

import { Link } from "react-router-dom"

const Sdheader = () => {
    
    const logout = () => {
        localStorage.clear()
        window.location.href="#/"
        window.location.reload()
    }

    return (
        <header className="login-headbox navbar-box">
            <nav className="navbar navbar-expand-lg login-headbox">
                <div className="container">
                    <a className="navbar-brand" href="/"> <img src="handshake-logo.png" height="35px" /> <span className="pagelogo">We Help</span></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item ms-2">
                                <Link className="nav-link active text-white nav-links" to="/"> <i class="bi bi-clipboard-data-fill"></i> My Dashboard</Link>
                            </li>

                            <li className="nav-item ms-2">
                                <Link className="nav-link active text-white nav-links" to="/tickets"> <i class="bi bi-ticket-perforated-fill"></i> My Tickets</Link>
                            </li>

                            <li className="nav-item ms-2">
                                <Link className="nav-link active  nav-links text-warning" to="/login" onClick={logout}> <i class="bi bi-power"></i> Log-out</Link>
                            </li>

                        </ul>
                    </div>
                </div>
            </nav>
        </header>
    )
}

export default Sdheader