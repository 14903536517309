
import { HashRouter, Routes, Route } from "react-router-dom"


import Sddashboard from "./SdDashboard"
import Sdtickets from "./SdTickets"
import Sdheader from "./SdHeader"


let SdApp = () => {
    return (
        <HashRouter>
            
            <Sdheader />

            <Routes>
                <Route exact path="/" element={ <Sddashboard /> } />
                <Route exact path="/tickets" element={ <Sdtickets /> } />
            </Routes>
        </HashRouter>
    )
}

export default SdApp