
import { Link } from "react-router-dom"
import swal from "sweetalert"
import { useState } from "react"

let Logindata = () => {

    let [email, pickemail] = useState("")
    let [password, pickPassword] = useState("")

    let goLogin = () => {
        let url = "https://cybotrix.com/webapi/login/auth";
        let userdata = { email: email, password: password };
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.json())
            .then(userinfo => {
                if (userinfo.status == "SUCCESS") {
                    localStorage.setItem("tokenno", userinfo.tokenno);
                    localStorage.setItem("fullname", userinfo.name);
                    localStorage.setItem("type", userinfo.type);
                    window.location.href="/"
                    window.location.reload();
                } else {
                    swal("Invalid Input","User may not exist","error")
                }
            })

    }

    return (
        <div>

            <header className="login-headbox">
                <nav className="navbar navbar-expand-lg login-headbox">
                    <div className="container">
                        <a className="navbar-brand" href="/"> <img src="handshake-logo.png" height="35px" /> <span className="pagelogo">We Help</span></a>
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                </nav>
            </header>

            <main>
                <h2 className="text-center mt-4 mb-2 page-header"> <img src="handshake-logo.png" height="40px" /> We Help </h2>
                <div className="container mt-5 ">
                    <div className="row">
                        <div className="col-lg-4"> </div>
                        <div className="col-lg-4 shadow p-0 login-table">
                            <div className="login-title">
                                <h3 className="pt-4 pb-4 ps-3 text-white mb-4"> <i className="bi bi-house-lock-fill"></i> Login </h3>
                            </div>

                                <div className="input-group  ps-4 pe-4 pb-2">
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-envelope-at-fill"></i></span>
                                    <input type="text" className="form-control" placeholder="Username or Email ID" aria-label="Username" aria-describedby="basic-addon1" value={email} onChange={event => pickemail(event.target.value)} />
                                </div>

                                <div className="input-group mb-3 ps-4 pe-4 pb-2">
                                    <span className="input-group-text" id="basic-addon1"><i className="bi bi-person-lock"></i></span>
                                    <input type="password" className="form-control" placeholder="Password" aria-label="Username" aria-describedby="basic-addon1" value={password} onChange={event => pickPassword(event.target.value)} />
                                </div>

                                <div className="text-center ps-4 pe-4">
                                    <button className="btn mb-1 form-control" id="login-button" onClick={goLogin}> Login <i className="bi bi-box-arrow-right ps-1"></i> </button>
                                </div>

                                <div className="ps-4 pe-4 text-center mb-5">
                                    <hr className="mb-3" />

                                    <a href="/forgot-password" className="forgot-pass normal-text"> Forgot Password ? </a>
                                </div>
                           
                        </div>
                        <div className="col-lg-4"> </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

export default Logindata