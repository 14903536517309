
import { HashRouter, Routes, Route } from "react-router-dom"


import Myaccount from "./AdAccount"
import Myheader from "./AdHeader"
import Mytickets from "./AdTickets"
import Mydashboard from "./AdDashboard"
import Newsupportaccount from "./newaccount"


let MyAdminApp = () => {
    return (
        <HashRouter>
            
            <Myheader />

            <Routes>
                <Route exact path="/" element={ <Mydashboard /> } />
                <Route exact path="/account" element={ <Myaccount /> } />
                <Route exact path="/tickets" element={ <Mytickets /> } />
                <Route exact path="/new-account" element={ <Newsupportaccount /> } />
            </Routes>
        </HashRouter>
    )
}

export default MyAdminApp