import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.bundle"

import Logindata from "./loginform"

const root = ReactDOM.createRoot(document.getElementById('root'));


if (localStorage.getItem("tokenno") == null)
  root.render(
    (<Logindata />)
  )
else
  root.render(
    (<App />)
  )

reportWebVitals();
