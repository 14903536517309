

import { useEffect, useState } from "react";
import gsap from "gsap";

const Mydashboard = () => {

    let [accounts, updateaccounts] = useState("Loading...")
    let [tickets, updatetickets] = useState("Loading...")
    let [assigned, updateassigned] = useState("Loading...")
    let [closed, updateaclosed] = useState("Loading...")
    let [open, updateopen] = useState("Loading...")

    const admindashboard = () => {
        let url = "https://cybotrix.com/webapi/api/dashboard";
        let userdata = { tokenno: localStorage.getItem("tokenno") };
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.json())
            .then(alldata => {
                updateaccounts(alldata.allaccount)
                updatetickets(alldata.allticket)
                updateassigned(alldata.totalassign)
                updateaclosed(alldata.totalclose)
                updateopen(alldata.totalopen)
            })
    }

    useEffect(() => {
        admindashboard()
        animations()
    }, [1])

    let animations = () =>{
        gsap.from(".dashboard-box",{
            opacity:0,
            y:-100,
            duration: 2,
            stagger: 0.2
        })
    }

    return (
        <div className="container">
            <h2 className="text-center mt-4 mb-5 page-header"> My Dashboard </h2>
            <div className="row mt-5 pe-1 text-center">

                
                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-people-fill dashboard-icon text-info"></i>
                    <div className="card-body">
                        <h5 className="card-title">Active Accounts : {accounts} </h5>
                    </div>
                </div>

                

                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-ticket-perforated dashboard-icon text-secondary"></i>
                    <div className="card-body">
                        <h5 className="card-title">New Tickets : {tickets} </h5>
                    </div>
                </div>
                
                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-person-check dashboard-icon text-warning"></i>
                    <div className="card-body">
                        <h5 className="card-title">Assigned Tickets : {assigned} </h5>
                    </div>
                </div>

                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-folder2-open dashboard-icon text-danger"></i>
                    <div className="card-body">
                        <h5 className="card-title">Open Tickets : {open} </h5>
                    </div>
                </div>

                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-check2-square dashboard-icon text-success"></i>
                    <div className="card-body">
                        <h5 className="card-title">Closed Tickets : {closed} </h5>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Mydashboard