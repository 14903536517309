import { useState } from "react"
import swal from "sweetalert"

let Newsupportaccount = () => {

    let [name, pickName] = useState("")
    let [email, pickEmail] = useState("")
    let [password, pickPassword] = useState("")
    let [mobile, pickMobile] = useState("")
    let [city, pickCity] = useState("")

    const save = () => {
        if (name == "" || email == "" || password == "" || mobile == "" || city == "") {
            swal("Invalid Input", "Please fill all mandatory fields !", "error")
        } else {
            let url = "https://cybotrix.com/webapi/api/createaccount";
            let userdata = {
                tokenno: localStorage.getItem("tokenno"),
                name: name,
                email: email,
                password: password,
                mobile: mobile,
                city: city
            };
            let postdata = {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(userdata)
            }
            fetch(url, postdata)
                .then(response => response.text())
                .then(msg => {
                    swal("Done", msg, "success")
                    pickName("")
                    pickEmail("")
                    pickPassword("")
                    pickMobile("")
                    pickCity("")
                })
        }
    }

    return (
        <div className="container mt-5">
            <div className="row">
                <div className="col-lg-4"></div>
                <div className="col-lg-4 p-0 newaccount-box">
                    <h2 className="page-header text-center pt-3 pb-4"> Create New Account </h2>

                    <div className="p-3">
                        <label className="input-field-text"> Fullname<span className="text-danger">*</span> </label> <br />
                        <input type="text" className="form-control mb-3" value={name} onChange={event => pickName(event.target.value)} />

                        <label className="input-field-text"> Email-Id<span className="text-danger">*</span> </label> <br />
                        <input type="email" className="form-control mb-3" value={email} onChange={event => pickEmail(event.target.value)} />

                        <label className="input-field-text"> Password<span className="text-danger">*</span> </label> <br />
                        <input type="password" className="form-control mb-3" value={password} onChange={event => pickPassword(event.target.value)} />

                        <label className="input-field-text"> Mobile<span className="text-danger">*</span> </label> <br />
                        <input type="text" className="form-control mb-3" value={mobile} onChange={event => pickMobile(event.target.value)} />

                        <label className="input-field-text"> City<span className="text-danger">*</span> </label> <br />
                        <input type="text" className="form-control" value={city} onChange={event => pickCity(event.target.value)} />
                    </div>

                    <div className="p-3 text-center">
                        <button className="form-control" id="account-button" onClick={save}>Create Account</button>
                    </div>
                </div>
                <div className="col-lg-4"></div>
            </div>
        </div>
    )
}

export default Newsupportaccount