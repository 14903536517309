import { useEffect, useState } from "react";
import gsap from "gsap";

const Sddashboard = () => {

    let [supportInfo, updateSupportInfo] = useState({})

    const supportdashboard = () => {
        let url = "https://cybotrix.com/webapi/supportapi/mydashboard";
        let userdata = { tokenno: localStorage.getItem("tokenno") };
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.json())
            .then(alldata => {
                updateSupportInfo(alldata)
            })
    }


    useEffect(() => {
        supportdashboard()
        animations()
    }, [1])

    let animations = () =>{
        gsap.from(".dashboard-box",{
            opacity:0,
            y:-100,
            duration: 2,
            stagger: 0.2
        })
    }

    return (
        <div className="container">
            <h2 className="text-center mt-4 mb-5 page-header"> My Dashboard </h2>
            <div className="row mt-5 pe-1 text-center">

                <div className="col-lg-1"></div>
                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-folder-symlink-fill dashboard-icon text-info"></i>
                    <div className="card-body">
                        <h5 className="card-title">All Tickets : {supportInfo.allticket} </h5>
                    </div>
                </div>


                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-person-fill-check dashboard-icon text-warning"></i>
                    <div className="card-body">
                        <h5 className="card-title">Assigned Tickets : {supportInfo.totalassign} </h5>
                    </div>
                </div>


                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-folder2-open dashboard-icon text-danger"></i>
                    <div className="card-body">
                        <h5 className="card-title">Open Tickets : {supportInfo.totalopen} </h5>
                    </div>
                </div>

                <div className="card col-lg-2 text-center m-3 dashboard-box" >
                    <i class="bi bi-check2-square dashboard-icon text-success"></i>
                    <div className="card-body">
                        <h5 className="card-title">Closed Tickets : {supportInfo.totalclose} </h5>
                    </div>
                </div>

                <div className="col-lg-1"></div>

            </div>
        </div>
    )
}

export default Sddashboard