

import MyAdminApp from "./Admin/AdminApp"
import SdApp from "./Support/SupportApp"


let App = () => {

  if (localStorage.getItem("type") == "ADMIN") {
    return (<MyAdminApp />)
  }
  else {
    return (<SdApp />)
  }
}

export default App

