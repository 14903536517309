
let Pagination = ({totalPosts, postsPerPage, setCurrentPage}) => {

    let pages = []

    for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) 
    {
        pages.push(i)
    }

    return (
       <div className="text-center mt-5 mb-5">
        {
            pages.map((page,index)=> {
                return <button className="btn btn-light me-3" id="paginate-button" key={index} onClick={()=>setCurrentPage(page)}> {page} </button>
            })
        }
       </div>
    )
}

export default Pagination