
import { Link } from "react-router-dom"

const Myheader = () => {
    
    const logout = () => {
        localStorage.clear()
        window.location.href="#/"
        window.location.reload()
    }

    return (

        <header className="login-headbox navbar-box">
            <nav className="navbar navbar-expand-lg login-headbox">
                <div className="container">
                    <a className="navbar-brand" href="/"> <img src="handshake-logo.png" height="35px" /> <span className="pagelogo">We Help</span></a>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                            <li className="nav-item ms-2">
                                <Link className="nav-link active text-white nav-links" to="/"> <i className="bi bi-clipboard-data-fill"></i> Admin Dashboard</Link>
                            </li>

                            <li className="nav-item ms-2">
                                <Link className="nav-link active text-white nav-links" to="/account"> <i className="bi bi-person-workspace"></i> Account</Link>
                            </li>

                            <li className="nav-item ms-2">
                                <Link className="nav-link active text-white nav-links" to="/tickets"> <i className="bi bi-ticket-perforated-fill"></i> My Tickets</Link>
                            </li>

                            <li className="nav-item ms-2">
                                <Link className="nav-link active text-white nav-links" to="/new-account"> <i class="bi bi-person-down"></i> Add New Account </Link>
                            </li>

                            <li className="nav-item ms-2">
                                <Link className="nav-link active  nav-links" to="/login" id="logout-text" onClick={logout}> <i class="bi bi-power"></i> Log-out</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Myheader


