
import { useState, useEffect } from "react"
import swal from "sweetalert";
import Pagination from "./Paginate";

const Mytickets = () => {

    let [alltickets, updateTickets] = useState([])

    let [statusdata, pickStatusInfo] = useState("")

    const allTicket = () => {
        let url = "https://cybotrix.com/webapi/api/allticket";
        let userdata = {
            tokenno: localStorage.getItem("tokenno"),
            status: statusdata
        }
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.json())
            .then(ticketArray => {
                updateTickets(ticketArray.reverse())
            })
    }

    useEffect(() => {
        allTicket()
        allAccount()
    }, [statusdata])

    // radio buttons get status

    let getstatus = (statusinfo) => {
        pickStatusInfo(statusinfo)
    }


    // Support Account dropdown list
    let [allaccounts, updateaccounts] = useState([])

    const allAccount = () => {
        let url = "https://cybotrix.com/webapi/api/allaccount";
        let userdata = { tokenno: localStorage.getItem("tokenno") };
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.json())
            .then(accountArray => {
                updateaccounts(accountArray.reverse())
            })
    }

    // Ticket status dropdown list

    let [status, pickStatus] = useState("")

    let [supportid, updateSupportAccount] = useState("")

    const ticketstatus = (ticketid) => {
        if (status == "" || supportid == "") {
            swal("Invalid Input", "Please check again", "error")
        } else {
            let url = "https://cybotrix.com/webapi/api/changestatus";
            let userdata = {
                tokenno: localStorage.getItem("tokenno"),
                ticketid: ticketid,
                supportid: supportid,
                statustype: status
            };
            let postdata = {
                headers: { 'Content-Type': 'application/json' },
                method: 'POST',
                body: JSON.stringify(userdata)
            }
            fetch(url, postdata)
                .then(response => response.text())
                .then(msg => {
                    swal("Updated", msg, "success")
                })
        }
    }

    // Pagination for Tickets

    let [currentPage, setCurrentPage] = useState(1)
    let [postsPerPage, setPostPerPage] = useState(10)

    const lastPostIndex = currentPage * postsPerPage
    const firstPostIndex = lastPostIndex - postsPerPage



    return (

        <div className="container">
            <h2 className="text-center mt-4 page-header mb-4">  New Tickets </h2>
            <div className="row mb-3 mt-2">
                <div className="col-lg-12 text-center">

                    <label >
                        <input className="form-check-input ms-5" type="radio" name="status" onClick={getstatus.bind(this, '')} />
                        <span className="radio-button-text ms-2">ALL TICKETS </span>
                    </label>
                    <label >
                        <input className="form-check-input ms-5" type="radio" name="status" onClick={getstatus.bind(this, 'OPEN')} />
                        <span className="radio-button-text ms-2">OPEN</span>
                    </label>

                    <label >
                        <input className="form-check-input ms-5" type="radio" name="status" onClick={getstatus.bind(this, 'ASSIGN')} />
                        <span className="radio-button-text ms-2">ASSIGN</span>
                    </label>

                    <label >
                        <input className="form-check-input ms-5" type="radio" name="status" onClick={getstatus.bind(this, 'CLOSE')} />
                        <span className="radio-button-text ms-2">CLOSE</span>
                    </label>


                </div>

            </div>
            <div className="row">
                <div className="col-lg-12">
                    <table className="table table-bordered table-hover shadow mt-3 mb-5" id="admin">
                        <thead>
                            <tr className="text-center align-middle">
                                <th> Sl No </th>
                                <th> Name </th>
                                <th> Ticket Status </th>
                                <th> Email </th>
                                <th> Issue </th>
                                <th> Details </th>
                                <th> Support </th>
                                <th> Status </th>
                                <th> Action </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                alltickets.slice(firstPostIndex, lastPostIndex).map((tickets, index) => {
                                    return (
                                        <tr key={index} className="text-center align-middle">
                                            <td> {tickets.id} </td>
                                            <td> {tickets.name} </td>
                                            <td> {tickets.status} </td>
                                            <td> {tickets.email} </td>
                                            <td> {tickets.problemtype} </td>
                                            <td> {tickets.message} </td>

                                            <td>
                                                <select className="form-select-sm bg-light" onChange={event => updateSupportAccount(event.target.value)}>
                                                    <option value=""> Choose</option>
                                                    {
                                                        allaccounts.map((support, index) => {
                                                            return (
                                                                <option key={index} value={support.userid}> {support.fullname} </option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            </td>

                                            <td>
                                                <select className="form-select-sm bg-light" onChange={event => pickStatus(event.target.value)}>
                                                    <option value=""> Choose </option>
                                                    <option value="OPEN"> OPEN </option>
                                                    <option value="ASSIGN"> ASSIGN </option>
                                                    <option value="CLOSE"> CLOSE </option>
                                                </select>
                                            </td>
                                            <td> <button className="btn btn-sm" id="update-button" onClick={ticketstatus.bind(this, tickets.id)}> Update </button> </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>

                    <Pagination totalPosts={alltickets.length} postsPerPage={postsPerPage} setCurrentPage={setCurrentPage} />
                </div>
            </div>
        </div>
    )
}

export default Mytickets