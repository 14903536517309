import { useEffect, useState } from "react";


const Myaccount = () => {

    let [allaccounts, updateaccounts] = useState( [] ) 

    const allAccount = () =>{
        let url = "https://cybotrix.com/webapi/api/allaccount";
        let userdata = {tokenno:localStorage.getItem("tokenno")};
        let postdata = {
             headers:{'Content-Type':'application/json'},
             method:'POST',
             body:JSON.stringify(userdata)
        }
        fetch(url, postdata)
        .then(response=>response.json())
        .then(accountArray=>{
            updateaccounts(accountArray.reverse())
        })
    }

    useEffect(()=>{
        allAccount()
    },[1])

    return (
        <div className="container">
            <h2 className="text-center mt-4 mb-3 page-header">  Active Accounts </h2>
            <div className="row">
                <div className="col-lg-12">
                    <table className="table table-bordered table-hover shadow mt-3" id="admin">
                        <thead>
                            <tr className="text-center">
                                <th> User Id </th>
                                <th> Name </th>
                                <th> City </th>
                                <th> Email </th>
                                <th> Mobile </th>
                                <th> Type </th>
                            </tr>
                        </thead>

                        <tbody>
                            {
                                allaccounts.map((tickets, index) => {
                                    return (
                                        <tr key={index}  className="text-center">
                                            <td> {tickets.userid} </td>
                                            <td> {tickets.fullname} </td>
                                            <td> {tickets.city} </td>
                                            <td> {tickets.email} </td>
                                            <td> {tickets.mobile} </td>
                                            <td> {tickets.type} </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                       
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Myaccount