
import { useState, useEffect } from "react";
import swal from "sweetalert";

const Sdtickets = () => {

    let [alltickets, updateTickets] = useState([])

    const allTicket = () => {
        let url = "https://cybotrix.com/webapi/supportapi/myallticket";
        let userdata = {
            tokenno: localStorage.getItem("tokenno"),
        }
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.json())
            .then(ticketArray => {
                if(ticketArray.length > 0){
                    updateTickets(ticketArray)
                    updatedetails(ticketArray[0])
                } else {
                    swal("Empty","No ticket Available","warning")
                }
            })
    }

    useEffect(() => {
        allTicket()
    }, [1])

    let [ticketdetails, updatedetails] = useState({})

    let getdetails = (ticketdata) => {
        updatedetails(ticketdata)
    }

    // Updating Ticket Status


    let [statusdata, updatestatusdata] = useState("")
    let [commentdetails, updatecomment] = useState("")

    const updateinfo = (ticketidinfo) => {
        let url = "https://cybotrix.com/webapi/supportapi/changestatus"
        let userdata = {
            tokenno: localStorage.getItem("tokenno"),
            ticketid: ticketidinfo,
            statustype: statusdata,
            comment: commentdetails
        }
        let postdata = {
            headers: { 'Content-Type': 'application/json' },
            method: 'POST',
            body: JSON.stringify(userdata)
        }
        fetch(url, postdata)
            .then(response => response.text())
            .then(ticketArray => {
                swal("Done",ticketArray,"success")
                allTicket()
            })
    }

    return (
        <div className="container mt-3">
            <h2 className="text-center mt-2 mb-3 page-header"> My Tickets </h2>
            <div className="row">
                <div className="col-lg-6">
                    <div className="card support-boxes shadow">
                        <div className="card-header support-box-header">
                            <h3 className="ps-3 pt-2 mb-2"> <i class="bi bi-person-workspace pe-2"></i> Avaiable Tickets </h3>
                        </div>

                        <div className="card-body">
                            <table className="table table-bordered table-hover" id="support">
                                <thead>
                                    <tr className="text-center">
                                        <th> Name </th>
                                        <th> Mobile </th>
                                        <th> Current Status </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        alltickets.map((ticketdata, index) => {
                                            return (
                                                <tr key={index} className="text-center" onClick={getdetails.bind(this, ticketdata)}>
                                                    <td className="ps-4 pe-4 pb-3"> {ticketdata.name} </td>
                                                    <td className="ps-4 pe-4 pb-3"> {ticketdata.mobile} </td>
                                                    <td className="ps-4 pe-4 pb-3"> {ticketdata.status} </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6">
                    <div className="card support-boxes shadow">
                        <div className="card-header support-box-header">
                            <h3 className="ps-3 pt-2"> <i class="bi bi-info-circle-fill pe-2"></i> Ticket Details </h3>
                        </div>

                        <div className="card-body">
                            <table className="table table-bordered table-hover" id="support-details">
                                <tbody>

                                    <tr>
                                        <th> Ticket Id </th>
                                        <td> {ticketdetails.id} </td>
                                    </tr>

                                    <tr>
                                        <th> Name </th>
                                        <td> {ticketdetails.name} </td>
                                    </tr>

                                    <tr>
                                        <th> Email Id </th>
                                        <td> {ticketdetails.email} </td>
                                    </tr>

                                    <tr>
                                        <th> Mobile No </th>
                                        <td> {ticketdetails.mobile} </td>
                                    </tr>

                                    <tr>
                                        <th> Created on </th>
                                        <td> {ticketdetails.createdon} </td>
                                    </tr>

                                    <tr>
                                        <th> Assigned on </th>
                                        <td> {ticketdetails.assigndate} </td>
                                    </tr>

                                    <tr>
                                        <th> Issue Type </th>
                                        <td> {ticketdetails.problemtype} </td>
                                    </tr>

                                    <tr>
                                        <th> Issue Description </th>
                                        <td> {ticketdetails.message} </td>
                                    </tr>

                                    <tr>
                                        <th> Choose Status </th>
                                        <td>
                                            <select className="form-select-sm bg-light" onChange={event => updatestatusdata(event.target.value)}>
                                                <option value=""> Choose </option>
                                                <option value="OPEN"> OPEN </option>
                                                <option value="ASSIGN"> ASSIGN </option>
                                                <option value="CLOSE"> CLOSE </option>
                                            </select>
                                        </td>
                                    </tr>

                                    <tr>
                                        <th> Add Comment </th>
                                        <td>
                                            <textarea placeholder="Add your comments here..." className="comment-section" maxLength="250" onChange={event => updatecomment(event.target.value)}></textarea>
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                            <div className="text-center rowspan-2">
                                <button className="btn ps-5 pe-5" id="update-button" onClick={updateinfo.bind(this,ticketdetails.id)}> Update Ticket </button>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        </div>
    )
}

export default Sdtickets

